// action types
import ApiService from '@/core/services/api.service'
import ProfileService from '@/core/services/profile.service'

export const UPDATE_PERSONAL_INFO = 'updateUserPersonalInfo'
export const UPDATE_ACCOUNT_INFO = 'updateUserAccountInfo'

// mutation types
export const SET_PERSONAL_INFO = 'setPersonalInfo'
export const SET_ACCOUNT_INFO = 'setAccountInfo'

const state = {
  user_personal_info: {
    photo: 'media/users/300_21.jpg',
    name: 'Nikola',
    surname: 'Jones',
    company_name: 'Fifestudios',
    job: 'Software Developer',
    email: 'matt@fifestudios.com',
    phone: '44(76)34254578',
    company_site: 'fifestudios'
  },
  user_account_info: {
    username: 'nick84',
    email: 'nick.watson@loop.com',
    roles: null,
    language: 'English',
    time_zone: '(GMT+01:00) Europe/Zagreb',
    communication: {
      email: true,
      sms: true,
      phone: false
    },
    verification: true
  }
}

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info
  },

  currentUserAccountInfo(state) {
    return state.user_account_info
  },

  currentUserPhoto(state) {
    return state.user_personal_info.photo
  },

  currentUserRoles(state) {
    return state.user_account_info.roles
  }
}

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    return new Promise(resolve => {
      ApiService.get('/users', `?email=${payload}`).then(({ data }) => {
        context.commit(SET_PERSONAL_INFO, data['hydra:member'][0].personalInfo)
        let acInfo = data['hydra:member'][0].accountInfo
        acInfo['roles'] = data['hydra:member'][0]['roles']
        context.commit(SET_ACCOUNT_INFO, acInfo)
        resolve(data)
      })
    })
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload)
  }
}

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    // state.user_personal_info = user_personal_info

    state.user_personal_info.photo = user_personal_info.photo
    state.user_personal_info.name = user_personal_info.name
    state.user_personal_info.surname = user_personal_info.surname
    state.user_personal_info.company_name = user_personal_info.company_name
    state.user_personal_info.job = user_personal_info.job
    state.user_personal_info.job = user_personal_info.job
    state.user_personal_info.email = user_personal_info.email
    state.user_personal_info.phone = user_personal_info.phone
    state.user_personal_info.company_site = user_personal_info.company_site

    ProfileService.saveProfilePersonalInfo(state.user_personal_info)
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    // state.user_account_info = user_account_info

    state.user_account_info.username = user_account_info.username
    state.user_account_info.email = user_account_info.email
    state.user_account_info.roles = user_account_info.roles
    state.user_account_info.language = user_account_info.language
    state.user_account_info.time_zone = user_account_info.time_zone
    state.user_account_info.communication.email =
      user_account_info.communication.email
    state.user_account_info.communication.sms =
      user_account_info.communication.sms
    state.user_account_info.communication.phone =
      user_account_info.communication.phone
    state.user_account_info.verification = user_account_info.verification

    ProfileService.saveProfileAccountInfo(state.user_account_info)
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
