import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/view/pages/Dashboard.vue')
        },
        {
          path: '/products',
          name: 'products',
          component: () => import('@/view/pages/products/Products'),
          children: [
            {
              path: 'list',
              name: 'products-list',
              component: () => import('@/view/pages/products/List.vue')
            },
            {
              path: 'new',
              name: 'product-new',
              component: () => import('@/view/pages/products/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'product-edit',
              component: () => import('@/view/pages/products/Edit.vue')
            }
          ]
        },
        {
          path: '/products-categories',
          name: 'products-categories',
          component: () =>
            import('@/view/pages/products-categories/ProductsCategories'),
          children: [
            {
              path: 'list',
              name: 'products-categories-list',
              component: () =>
                import('@/view/pages/products-categories/List.vue')
            },
            {
              path: 'new',
              name: 'products-categories-new',
              component: () =>
                import('@/view/pages/products-categories/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'products-categories-edit',
              component: () =>
                import('@/view/pages/products-categories/Edit.vue')
            }
          ]
        },
        {
          path: '/products-attributes',
          name: 'products-attributes',
          component: () =>
            import('@/view/pages/products-attributes/ProductsAttributes'),
          children: [
            {
              path: 'list',
              name: 'products-attributes-list',
              component: () =>
                import('@/view/pages/products-attributes/List.vue')
            },
            {
              path: 'new',
              name: 'products-attributes-new',
              component: () =>
                import('@/view/pages/products-attributes/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'products-attributes-edit',
              component: () =>
                import('@/view/pages/products-attributes/Edit.vue')
            }
          ]
        },
        {
          path: '/brands',
          name: 'brands',
          component: () => import('@/view/pages/brands/Brands'),
          children: [
            {
              path: 'list',
              name: 'brands-list',
              component: () => import('@/view/pages/brands/List.vue')
            },
            {
              path: 'new',
              name: 'brand-new',
              component: () => import('@/view/pages/brands/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'brand-edit',
              component: () => import('@/view/pages/brands/Edit.vue')
            }
          ]
        },
        {
          path: '/orders',
          name: 'orders',
          component: () => import('@/view/pages/orders/Orders'),
          children: [
            {
              path: 'list',
              name: 'orders-list',
              component: () => import('@/view/pages/orders/List')
            },
            {
              path: 'details/:id',
              name: 'order-details',
              component: () => import('@/view/pages/orders/Details')
            }
          ]
        },
        {
          path: '/posts',
          name: 'posts',
          component: () => import('@/view/pages/posts/Posts'),
          children: [
            {
              path: 'list',
              name: 'posts-list',
              component: () => import('@/view/pages/posts/List.vue')
            },
            {
              path: 'new',
              name: 'post-new',
              component: () => import('@/view/pages/posts/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'post-edit',
              component: () => import('@/view/pages/posts/Edit.vue')
            }
          ]
        },
        {
          path: '/companies',
          name: 'companies',
          component: () => import('@/view/pages/company/Company'),
          children: [
            {
              path: 'list',
              name: 'companies-list',
              component: () => import('@/view/pages/company/List.vue')
            },
            {
              path: 'new',
              name: 'company-new',
              component: () => import('@/view/pages/company/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'company-edit',
              component: () => import('@/view/pages/company/Edit.vue')
            }
          ]
        },
        {
          path: '/discounts-bundles',
          name: 'discounts-bundles',
          component: () =>
            import('@/view/pages/discounts-bundles/DiscountsBundles'),
          children: [
            {
              path: 'list',
              name: 'discounts-bundles-list',
              component: () => import('@/view/pages/discounts-bundles/List.vue')
            },
            {
              path: 'new',
              name: 'discounts-bundles-new',
              component: () => import('@/view/pages/discounts-bundles/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'discounts-bundles-edit',
              component: () => import('@/view/pages/discounts-bundles/Edit.vue')
            }
          ]
        },
        {
          path: '/users',
          name: 'users',
          component: () => import('@/view/pages/users/Users'),
          children: [
            {
              path: 'list',
              name: 'users-list',
              component: () => import('@/view/pages/users/List')
            },
            {
              path: 'new',
              name: 'users-new',
              component: () => import('@/view/pages/users/New')
            },
            {
              path: 'edit/:id',
              name: 'users-edit',
              component: () => import('@/view/pages/users/Edit')
            }
          ]
        },
        {
          path: '/categories',
          name: 'categories',
          component: () => import('@/view/pages/categories/Categories'),
          children: [
            {
              path: 'list',
              name: 'categories-list',
              component: () => import('@/view/pages/categories/List.vue')
            },
            {
              path: 'new',
              name: 'categories-new',
              component: () => import('@/view/pages/categories/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'categories-edit',
              component: () => import('@/view/pages/categories/Edit.vue')
            }
          ]
        },
        {
          path: '/tags',
          name: 'tags',
          component: () => import('@/view/pages/tags/Tags'),
          children: [
            {
              path: 'list',
              name: 'tags-list',
              component: () => import('@/view/pages/tags/List.vue')
            },
            {
              path: 'new',
              name: 'tags-new',
              component: () => import('@/view/pages/tags/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'tags-edit',
              component: () => import('@/view/pages/tags/Edit.vue')
            }
          ]
        },
        {
          path: '/pages',
          name: 'pages',
          component: () => import('@/view/pages/pages/Pages'),
          children: [
            {
              path: 'list',
              name: 'pages-list',
              component: () => import('@/view/pages/pages/List.vue')
            },
            {
              path: 'new',
              name: 'pages-new',
              component: () => import('@/view/pages/pages/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'pages-edit',
              component: () => import('@/view/pages/pages/Edit.vue')
            }
          ]
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/view/pages/profile/Profile'),
          children: [
            {
              path: 'overview',
              name: 'profile-overview',
              component: () => import('@/view/pages/profile/Profile-1.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/view/pages/error/Error.vue'),
      children: [
        {
          path: 'error-1',
          name: 'error-1',
          component: () => import('@/view/pages/error/Error-1.vue')
        },
        {
          path: 'error-2',
          name: 'error-2',
          component: () => import('@/view/pages/error/Error-2.vue')
        },
        {
          path: 'error-3',
          name: 'error-3',
          component: () => import('@/view/pages/error/Error-3.vue')
        },
        {
          path: 'error-4',
          name: 'error-4',
          component: () => import('@/view/pages/error/Error-4.vue')
        },
        {
          path: 'error-5',
          name: 'error-5',
          component: () => import('@/view/pages/error/Error-5.vue')
        },
        {
          path: 'error-6',
          name: 'error-6',
          component: () => import('@/view/pages/error/Error-6.vue')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/login_pages/Login-1'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/login_pages/Login-1')
        },
        {
          name: 'register',
          path: '/register',
          component: () => import('@/view/pages/auth/login_pages/Login-1')
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue')
    }
  ]
})
