const PROFILE_PERSONAL_INFO = 'profile_personal_info'
const PROFILE_ACCOUNT_INFO = 'profile_account_info'

export const getProfilePersonalInfo = () => {
  return JSON.parse(window.localStorage.getItem(PROFILE_PERSONAL_INFO))
}
export const getProfileAccountInfo = () => {
  return JSON.parse(window.localStorage.getItem(PROFILE_ACCOUNT_INFO))
}

export const saveProfilePersonalInfo = personalInfo => {
  window.localStorage.setItem(
    PROFILE_PERSONAL_INFO,
    JSON.stringify(personalInfo)
  )
}

export const saveProfileAccountInfo = accountInfo => {
  window.localStorage.setItem(PROFILE_ACCOUNT_INFO, JSON.stringify(accountInfo))
}

export const destroyProfilePersonalInfo = () => {
  window.localStorage.removeItem(PROFILE_PERSONAL_INFO)
}
export const destroyProfileAccountInfo = () => {
  window.localStorage.removeItem(PROFILE_ACCOUNT_INFO)
}

export default {
  getProfilePersonalInfo,
  getProfileAccountInfo,
  saveProfilePersonalInfo,
  saveProfileAccountInfo,
  destroyProfilePersonalInfo,
  destroyProfileAccountInfo
}
