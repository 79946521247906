import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function(value) {
  if (value) return moment(String(value)).format('DD.MM.YYYY.')
})

Vue.filter('formatTime', function(value) {
  if (value) return moment(String(value)).format('HH:mm')
})

Vue.filter('formatDateTime', function(value) {
  if (value) return moment(String(value)).format('DD.MM.YYYY. @ HH:mm')
})

Vue.filter('currencyFormat', function(value) {
  if (value) {
    return value
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
})
